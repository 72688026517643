import React from "react";
import { withRouter } from "react-router";
import { Col, Row } from "reactstrap";
import * as moment from "moment";
import Swiper from "react-id-swiper";

import Paper from "@material-ui/core/Paper/Paper";

export default withRouter(function DetailView(props: any) {
  const details = props?.digest?.find(
    (news: any) =>
      news?._id?.$id === props?.match?.params?.blog ||
      news?.id_str === props?.match?.params?.blog ||
      news?.facebookPostID === props?.match?.params?.blog ||
      news?.eventID === props?.match?.params?.blog ||
      news?.epoch === Number(props?.match?.params?.blog)
  );

  const params = {
    slidesPerView: 1,
    loop: details?.images?.length > 1,
    autoplay: {
      delay: 2750,
      disableOnInteraction: false,
    },
    pagination:
      details?.images?.length > 1
        ? {
            el: ".swiper-pagination",
            bulletElement: "div",
            clickable: true,
          }
        : {},
  };

  return (
    <Row>
      <Col xs={12} md={10} className="my-4">
        <button
          style={{
            backgroundColor: props?.manifestData?.stylesheet?.colors?.primary,
          }}
          className="p-2 rounded-full my-2 text-gray-50"
          onClick={() => props?.history?.push(`/${props?.route}`)}
        >
          &larr; Back to List
        </button>
        <Paper className="p-4">
          <h2 className="text-xl font-semibold">{details?.title}</h2>
          {props?.viewData?.config?.displayDate && (
            <strong>
              Posted on{" "}
              {props?.viewData?.config?.dateFormat
                ? moment
                    .unix(details?.epoch)
                    .format(`${props?.viewData?.config?.dateFormat}`)
                    .toString()
                : moment
                    .unix(details?.epoch)
                    .format("ddd, MMM DD, YYYY, hh:mm A z")
                    .toString()}
              <hr className="bg-yellow-400 w-2/12 py-0.5"></hr>
            </strong>
          )}
          <p
            className="mt-3 break-words"
            dangerouslySetInnerHTML={{
              __html: details?.content
                .replaceAll(
                  "<h6>",
                  '<h6 class="text-xs font-bold break-words">'
                )
                .replaceAll(
                  "<h5>",
                  '<h5 class="text-sm font-bold break-words">'
                )
                .replaceAll(
                  "<h4>",
                  '<h4 class="text-base font-bold break-words">'
                )
                .replaceAll(
                  "<h3>",
                  '<h3 class="text-xl font-bold break-words">'
                )
                .replaceAll(
                  "<h2>",
                  '<h2 class="text-2xl font-bold break-words">'
                )
                .replaceAll(
                  "<h1>",
                  '<h1 class="text-4xl font-bold break-words">'
                )
                .replaceAll("<ul>", '<ul class="list-disc m-2 p-2">')
                .replaceAll("<ol>", '<ol class="list-decimal m-2 p-2">')
                .replaceAll(
                  "<a",
                  '<a class="underline text-blue-800 break-words" '
                ),
            }}
          />
        </Paper>
        <div className="flex justify-center items-center my-2">
          <Swiper {...params}>
            {details?.images?.length > 0 &&
              details?.images?.map((image: any, index: number) => {
                return (
                  <div
                    className="w-full flex justify-center py-5"
                    key={`${image?.large + index}`}
                  >
                    <img
                      className="max-h-96 object-contain"
                      src={image?.large ? image?.large : image?.small}
                      alt={details[index]?.summary}
                    />
                  </div>
                );
              })}
          </Swiper>
        </div>
      </Col>
    </Row>
  );
});
